import * as reducers from '../store/reducers'
import { UrlParams } from '../constants/UrlParams'
import { Magic, RPCError, RPCErrorCode } from 'magic-sdk'
import { tokenService } from '../services/token'
import { AuthProviders } from '../constants/AuthProviders'
import { sendWelcomeEmail, sendRegistrationEmail } from './index'
import { getPlanName } from '@anewgo/functions'
import { authenticateProspect } from '../services/prospect'
import { EVT_USER_ALIAS } from '../constants/eventTracking'
import { persistentStorage } from '@anewgo/storage'

const WELCOME_MESSAGE_TIMEOUT = 2000
let interval = null
export const magic = new Magic(process.env.REACT_APP_MAGIC_LINK_API_KEY)

export function __logRpcError(err) {
  if (err instanceof RPCError) {
    switch (err.code) {
      case RPCErrorCode.MagicLinkFailedVerification:
        console.log('Magic link failed verification')
        break
      case RPCErrorCode.MagicLinkExpired:
        console.log('Magic link expired')
        break
      case RPCErrorCode.MagicLinkRateLimited:
        console.log('Magic link rate limited')
        break
      case RPCErrorCode.UserAlreadyLoggedIn:
        console.log('user already logged in')
        break
      default:
        console.log('Unknown Magic Link error')
        break
    }
  }
}

// Auto logout user if token expires
export const setAutoLogout = (
  dispatch,
  clientName,
  uiConfig,
  expirationTimestamp
  // auth0Logout
) => {
  const futureDate = new Date(expirationTimestamp)

  interval = setInterval(async () => {
    const nowDate = new Date()
    if (futureDate.getTime() < nowDate.getTime()) {
      await signOut({
        dispatch,
        clientName,
        uiConfig,
        isAutoLogOut: true,
        // auth0Logout,
      })
    }
  }, 10000)
}

export async function signOut({
  dispatch,
  clientName,
  uiConfig,
  isAutoLogOut,
  // auth0Logout,
}) {
  if (interval) {
    clearInterval(interval)
  }

  try {
    const provider = tokenService.token()?.provider
    switch (provider) {
      case AuthProviders.FACEBOOK:
        // check for Facebook Login
        window.FB.getLoginStatus(async (response) => {
          if (response.status === 'connected') {
            window.FB.logout((response) => {
              dispatch(reducers.signOut())
            })
          }
        })
        break
      case AuthProviders.MAGIC:
        // check for magicLink Login
        try {
          const isLoggedIn = await magic.user.isLoggedIn()
          if (isLoggedIn) {
            await magic.user.logout()
            dispatch(reducers.setUserProfile(null))
          }
        } catch (err) {
          __logRpcError(err)
        }
        break
      default:
        dispatch(reducers.signOut())
        break
    }
    // auth0Logout()
    // dispatch(reducers.signOut())
  } catch (err) {
    console.error('Failed to sign-out from external providers:', err)
  }

  tokenService.clear()

  if (uiConfig.localAgent) {
    persistentStorage.setItem('localAgent', JSON.stringify(uiConfig.localAgent))
  }

  window.location.replace(
    `${process.env.REACT_APP_TRUSS}${
      isAutoLogOut ? `?${UrlParams.autoLogOut}` : `/client/${clientName}`
    }`
  )
}

export function displayWelcomeMessage(newProspect, dispatch) {
  let welcomeMessage
  // Greet the user on sign-in.
  if (newProspect.name) {
    welcomeMessage = `Welcome, ${newProspect.name.split(' ')[0]}!`
  } else {
    welcomeMessage = `Welcome, ${newProspect.email}!`
  }
  dispatch(reducers.openAuthenticatedDialog(welcomeMessage))

  setTimeout(() => {
    dispatch(reducers.closeAuthenticatedDialog())
  }, WELCOME_MESSAGE_TIMEOUT)
}

function getFloorplanOfInterestFromSelection({ plan, elevation }) {
  return plan && `${getPlanName(plan)} ${elevation?.caption || ''}`.trim()
}

export async function processProspect(
  newProspect,
  anonymousProspect,
  dispatch,
  track,
  clientName,
  newSignIn,
  hasConsent,
  selection,
  uiConfig
) {
  dispatch(
    // Change app state to signed in (and synced to false).
    reducers.signIn(),
    // Actually store the prospect data in our app data store
    reducers.setProspect(newProspect, newProspect.favorites),
    reducers.setIsProspectFetching(false),
    reducers.setDbFavoritesLoaded(),
    reducers.restoreSelectionFromFavoritesList()
  )

  if (newSignIn) {
    displayWelcomeMessage(newProspect, dispatch)
  }
  if (hasConsent) {
    // communityId and floorplanOfInterest will be left undefined if the prospect already has
    // favorites. This will prevent the "floorplan of interest" from simply being the current
    // selection, which we only want when there are no favorites.
    const agentEmail = uiConfig?.localAgent?.email || null
    await sendWelcomeEmail(
      newProspect,
      clientName,
      newProspect?.favorites || [],
      selection?.community?.id,
      agentEmail
    )

    await sendRegistrationEmail(
      {
        ...newProspect,
        communityId: newProspect.communityId ?? selection?.community?.id,
        floorplanOfInterest:
          newProspect.floorplanOfInterest ??
          getFloorplanOfInterestFromSelection(selection),
      },
      clientName
    )
  }
}

export async function bootstrapProspect({
  clientName,
  dispatch,
  selection,
  anonymousProspect,
  track,
  authType,
  accessToken,
  uiConfig,
  loginCompletionEventHandlerFn,
  // failedAuthLogOut,
}) {
  const communityId = selection?.community?.id

  const planName =
    selection.plan &&
    `${getPlanName(selection.plan)} ${
      selection?.elevation?.caption || ''
    }`.trim()

  const prospectTokenData = await authenticateProspect(
    accessToken,
    clientName,
    authType,
    communityId,
    planName,
    uiConfig?.localAgent?.name,
    uiConfig?.localAgent?.email,
    uiConfig?.localAgent?.phone
  )
  if (!prospectTokenData) {
    console.error('Failed to authenticate prospect!')
    return
  }
  /*
  // Commenting out Auth0 code since we will wait to MHA 3.0 to switch
  let prospectTokenData
  try {
    prospectTokenData = await authenticateProspect(
      accessToken,
      clientName,
      communityId,
      planName,
      uiConfig?.localAgent?.name,
      uiConfig?.localAgent?.email,
      uiConfig?.localAgent?.phone
    )
    if (!prospectTokenData) {
      console.error('Failed to authenticate prospect!')
      failedAuthLogOut('Failed to authenticate prospect!')
      return
    }
  } catch (err) {
    console.error(err)
    failedAuthLogOut(err.message)
  }
  */

  try {
    // Use the profile data to create or update a Prospect.
    // Retrieve the Prospect.
    let email = prospectTokenData.prospect?.email
    if (!email) {
      // failedAuthLogOut('No email for prospect')
      throw new Error('No email for prospect')
    }
    tokenService.setToken(
      authType,
      prospectTokenData.prospect,
      prospectTokenData.token.value,
      prospectTokenData.token.exp,
      prospectTokenData.isRegistered
    )

    let token = tokenService.token()
    if (!token) {
      /*
      failedAuthLogOut(
        'Authorization Invalid. Please log in again to continue.'
      )
      */
      return
    }
    setAutoLogout(dispatch, clientName, uiConfig, token.exp)
    /*
    setAutoLogout(dispatch, clientName, uiConfig, token.exp, () =>
      failedAuthLogOut('Session Expired. Please sign in again.')
    )
    */
  } catch (error) {
    // Log a more detailed error message.
    console.error(
      'Encountered an error while initializing a prospect (and favorites) from profile data:',
      error
    )
    // Rethrow the error to avoid executing any other code, and trigger a simpler error message in
    // a pop-up.
    throw error
  }

  // Add any other prospect-related fields that are not stored in the database, but our app needs.
  const newProspect = {
    ...prospectTokenData.prospect,
    communityId: communityId || undefined,
    floorplanOfInterest: planName || undefined,
  }

  // Track sign-in activity.
  track(
    EVT_USER_ALIAS,
    {
      lastUserId: anonymousProspect,
      nextUserId: newProspect.email,
      userAction: 'sign-in',
    },
    {
      newProspect,
    }
  )

  // IF TOKEN AND REGISTERED PROCESS PROSPECT
  if (prospectTokenData.isRegistered) {
    await processProspect(
      newProspect,
      anonymousProspect,
      dispatch,
      track,
      clientName,
      true
    )
  }
  // Call the proper callback function to close the sign in dialog and open the
  // phone/contact preferences dialog if needed for initial registration.
  if (loginCompletionEventHandlerFn) {
    loginCompletionEventHandlerFn(
      prospectTokenData.isRegistered,
      prospectTokenData.prospect,
      prospectTokenData.token,
      authType
    )
  }
}
